<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>

			<div class="w-100 h-100 position-relative overflow-hidden">

				<div class="w-100 h-100 d-flex">

					<div class="d-flex flex-column w-100 h-100">

						<workspace_nav label="Add workspace" />

						<div class="w-100 h-100 overflow-auto">
						
							<div class="container h-100 py-4">
								
								<div class="row h-100 justify-content-center">

									<div class="col-12 col-md-12 col-lg-10 col-xl-8">

										<loader v-if="team_loading" />

										<div v-else class="card mb-3 overflow-hidden">

											<div class="row m-0">

												<div class="col px-2">

													<div v-if="auth_user.has_payment_method" class="card-body">

														<form @submit.prevent="create_workspace">

															<h2>Add workspace details.</h2>

															<p v-if="error.message" class="alert alert-danger">
																{{ error.message }}
															</p>

															<p class="alert alert-info small">
																A workspace is a private server that will store ongoing web projects. A good name for your workpsace would be something like "{{ team.name }} 1". The workspace name cannot be changed after your workspace is created.
															</p>

															<form_control
																label="Number of projects"
																type="select"
																name="size"
																placeholder="Select workspace"
																:options="size_options"
																:required="true"
																:error="error"
																v-model="size"
															/>

															<form_control
																label="Workspace name"
																type="text"
																name="name"
																:placeholder="'eg: ' + team.name"
																help="Only use text and numbers, do not include any special characters."
																:required="true"
																:error="error"
																v-model="name"
															/>

															<!-- <form_control
																label="Your password"
																type="password"
																name="password"
																help="Confirm your account password to proceed"
																:required="true"
																:error="error"
																v-model="password"
															/> -->

															<btn_submit 
																:label="auth_user.workspace_count < 1 ? 'Create workspace and start trial' : 'Make payment and create workspace'" 
																icon="add_circle_outline"  
																:icon_after="true" 
																:loading="loading" 
															/>

														</form>

													</div>

													<div v-else class="card-body">

														<form class="position-relative" @submit.prevent="create_setup_intent">

															<loader_overlay v-if="stripe_loading" />

															<h2>Billing address.</h2>

															<p v-if="error.message" class="alert alert-danger">
																{{ error.message }}
															</p>

															<form_control
																label="Address line 1"
																type="text"
																name="line_1"
																:required="true"
																:error="error"
																v-model="team.line_1"
															/>

															<div class="row">

																<div class="col">

																	<form_control
																		label="Town / city"
																		type="text"
																		name="city"
																		:required="true"
																		:error="error"
																		v-model="team.city"
																	/>

																</div>

																<div class="col">

																	<form_control
																		label="Postcode / zipcode"
																		type="text"
																		name="postcode"
																		:required="true"
																		:error="error"
																		v-model="team.postcode"
																	/>

																</div>

															</div>

															<div class="row">

																<div class="col">
																	<form_control
																		label="County / state"
																		type="text"
																		name="state"
																		:required="true"
																		:error="error"
																		v-model="team.state"
																	/>
																</div>

																<div class="col">
																	<form_control
																		label="Country"
																		type="select"
																		name="country"
																		:options="store_countries"
																		:required="true"
																		:error="error"
																		v-model="team.country"
																	/>
																</div>

															</div>

															<h2 class="border-top pt-3">Payment method.</h2>

															<label class="form-label">Card Number</label>
															<div id="card-element-number" class="card-element position-relative mb-3 form-control p-3"></div>

															<div class="row mb-4">
																<div class="col">
																	<label class="form-label">Expiry</label>
																	<div id="card-element-expiry" class="card-element position-relative form-control p-3"></div>
																</div>
																<div class="col">
																	<label class="form-label">CVC</label>
																	<div id="card-element-cvc" class="card-element position-relative form-control p-3"></div>
																</div>
															</div>

															<btn_submit 
																label="Add payment method" 
																icon="add_circle_outline"  
																:icon_after="true" 
																:loading="stripe_loading" 
															/>

														</form>

													</div>

												</div>

												<div class="col-4 bg-light border-start">

													<div class="card-body px-2">

														<a href="https://stripe.com/" target="_blank" class="d-block mb-4">
															<img src="@/assets/img/powered-by-stripe.png" height="35">
														</a>

														<div class="list-group mb-4">
															<div class="list-group-item d-flex justify-content-between align-items-center">
																<span class="fw-bold">Subtotal</span>
																<span>£{{ subtotal.toFixed(2) }}</span>
															</div>
															<div class="list-group-item d-flex justify-content-between align-items-center">
																<span class="fw-bold">VAT (20%)</span>
																<span>£{{ vat.toFixed(2) }}</span>
															</div>
															<div class="list-group-item d-flex justify-content-between align-items-center">
																<span class="fw-bold">Total</span>
																<span>£{{ total.toFixed(2) }}</span>
															</div>
														</div>

														<p v-if="auth_user.has_payment_method && auth_user.workspace_count < 1" class="small">If you proceed, your free 15 day trial will begin. If you do not cancel your subscription before the trial ends the above amount will be taken from your payment method in 15 days and will continue on a monthly basis. <br><br>Cancel at anytime by deleting your workspace.</p>

														<p v-else-if="auth_user.has_payment_method" class="small">If you proceed, this amount will be taken now and will continue on a monthly basis. <br><br>Cancel at anytime by deleting your workspace.</p>

														<p v-else class="small">Your card will not be charged yet.</p>											
													
													</div>

												</div>

											</div>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import loader from '@/components/loader'
import loader_overlay from '@/components/loader_overlay'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import main_nav from '@/components/main_nav'
import support_link from '@/components/support_link'
import workspace_nav from '@/components/workspace_nav'
import workspace_service from '@/services/workspace_service'
import billing_service from '@/services/billing_service'
import team_service from '@/services/team_service'
import user_service from '@/services/user_service'

export default {
	name: 'workspaces',

	components: {
		layout,
		loader,
		loader_overlay,
		main_nav,
		support_link,
		form_control,
		btn_submit,
		workspace_nav
	},

	data() {
		return {
			loading: false,
			stripe_loading: false,
			team_loading: false,
			name: null,
			size: 'agency',
			description: null,
			password: null,
			terms: null,
			stripe: null,
			secret: null,
			team: {
				name: null,
				line_1: null,
				city: null,
				state: null,
				postcode: null,
				country: 'GB'
			},
			error: {},
			size_options: [
				{
					name: "Freelancer ( 1 ongoing project - £149pm )",
					value: 'freelancer'	
				},
				{
					name: "Agency ( Upto 5 ongoing projects - £299pm )",
					value: 'agency'	
				},
				{
					name: "Enterprise ( Upto 10 ongoing projects - £499pm )",
					value: 'enterprise'	
				}
			]
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		...mapGetters( 'countries', ['store_countries'] ),

		subtotal()
		{
			if ( this.size == 'freelancer' ) {
				return 149;
			} else if ( this.size == 'enterprise' ) {
				return 499;
			} else {
				return 299;
			}
		},

		vat()
		{
			return this.subtotal * 0.2;
		},

		total()
		{
			return this.subtotal + this.vat;
		}

	},

	mounted()
	{
		this.team_show()

		if ( !this.auth_user.has_payment_method ) {
			this.create_intent()
		}
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async team_show()
		{
			this.team_loading = true 

			this.reset_error()

			team_service.show( this.auth_user.team_id ).then(( response ) => {

				this.team = response.data

			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.team_loading = false
			})
		},

		async create_workspace()
		{
			this.loading = true 

			this.reset_error()

			workspace_service.store({
				size: this.size,
				name: this.name,
				description: this.description,
				password: this.password,
				terms: this.terms
			}).then(() => {
				this.$router.push( { name: 'workspaces' } )
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async create_intent() {

			this.stripe_loading    = true
			
			this.reset_error()

			await billing_service.create_intent().then(( response ) => {
				
				this.secret = response.data.client_secret

				this.create_element()
				
			}).catch((error) => {
				this.error = error
			})
		}, 

		async create_element()
		{			
			var vm = this

			if ( !window.$('#card-element-number').length ) {
				
				setTimeout( function() {
					vm.create_element()
				}, 500);
				
				return false
			}
			
			this.reset_error()

			this.stripe = window.Stripe( process.env.VUE_APP_STRIPE_KEY );

			this.elements = this.stripe.elements();

			this.card_element_number = this.elements.create( 'cardNumber' );
			this.card_element_number.mount('#card-element-number');

			this.card_element_expiry = this.elements.create( 'cardExpiry' );
			this.card_element_expiry.mount('#card-element-expiry');

			this.card_element_cvc = this.elements.create( 'cardCvc' );
			this.card_element_cvc.mount('#card-element-cvc');

			this.card_element_number.on('ready', function() {
				vm.stripe_loading = false
			});
		},

		async create_setup_intent()
		{
			this.stripe_loading = true
			
			let client_secret = this.secret

			const { setupIntent, error } = await this.stripe.confirmCardSetup( client_secret, {
				payment_method: {
					card: this.card_element_number,
					billing_details: {
						name: this.team.name,
						email: this.team.email,
						address: {
							city: this.team.city,
							state: this.team.state,
							postal_code: this.team.postcode,
							country: this.team.country,
						},
					}
				}
			});

			if ( error ) {
				this.error = error
				this.stripe_loading = false
			} else {

				this.add_payment_method( setupIntent )
			}
		},

		async add_payment_method( setup_intent )
		{
			await billing_service.add_payment_method( {
				intent: setup_intent,
				team: this.team
			} ).then(() => {
				
				this.reset_error()

				user_service.auth().then(( response ) => {
					
					this.$store.commit( 'user/SET_USER', response.data )

				}).catch( ( error ) => {
					this.error = error
				})
				
			}).catch((error) => {
				this.error = error
			})
		}
	}
}
</script>

<style>

</style>
